@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto+Slab&display=swap");

@font-face {
  font-family: phanisaimunipallifont;
  src: url(./fonts/bogle/BOGLEREGULAR.OTF);
}

.App {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100vh;
  flex-direction: row;
  font-family: phanisaimunipallifont, "Open Sans", "Roboto Slab", Georgia,
    "Times New Roman", Times, serif;
}

.navbar-custom {
  font-size: large;
  background-color: black;
}

.navbar__link {
  margin-left: 0.75em;
  margin-right: 0.75em;
  font-size: 1em;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.1em;
  text-indent: 0.3em;
  border-bottom: 3px solid transparent;
}

.navbar__link::after {
  transition: all ease-in-out 0.2s;
  background: none repeat scroll 0 0;
  content: "";
  display: block;
  margin-top: 2px;
  height: 3px;
  width: 0;
}

.navbar__link:hover::after {
  visibility: visible;
  width: 40%;
}

.navbar__link--active::after {
  transition: all ease-in-out 0.2s;
  width: 100%;
}

.navbar__link--active:hover::after {
  width: 100%;
}

.MainApp {
  margin-top: 8vh;
  height: 90vh;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.section-content-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.section-achieve-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 70%;
  font-family: phanisaimunipallifont, "Open Sans", "Roboto Slab", Georgia,
    "Times New Roman", Times, serif;
}

.Typewriter {
  display: inline-block;
}

.Typewriter__wrapper {
  font-size: xx-large;
  font-weight: bold;
}

.Typewriter__cursor {
  font-size: xx-large;
  font-weight: bold;
}

section.timeline-card-content {
  justify-content: center;
}

section.timeline-card-content p.card-title {
  font-family: phanisaimunipallifont, "Open Sans", "Roboto Slab", Georgia,
    "Times New Roman", Times, serif;
  font-size: 1.5em;
  margin-top: 25px;
}

section.timeline-card-content p.card-sub-title {
  font-family: phanisaimunipallifont, "Open Sans", "Roboto Slab", Georgia,
    "Times New Roman", Times, serif;
  font-size: 1.25em;
  margin-top: 10px;
}

section.timeline-card-content div.card-description > p {
  font-family: phanisaimunipallifont, "Open Sans", "Roboto Slab", Georgia,
    "Times New Roman", Times, serif;
  font-size: 1.2em;
  margin-top: 10px;
  font-weight: 500;
}

.social {
  position: relative;
  margin-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .navbar__link {
    margin-left: 0.4em;
    margin-right: 0.4em;
    font-size: 0.9em;
    cursor: pointer;
    letter-spacing: 0.1em;
    text-indent: 0.2em;
  }
}

@media (max-width: 768px) {
  .navbar__link::after {
    background: none;
    content: "";
    display: none;
    margin-top: 0;
    height: 0;
    width: 0;
  }

  .navbar__link--active::after {
    width: 0;
  }

  .navbar__link--active:hover::after {
    width: 0;
  }

  .navbar__link:hover::after {
    width: 0;
  }
}

.header {
  font-size: 3em;
  font-weight: bold;
  margin-bottom: 25px;
}

.a-main-article {
  text-decoration: none;
  font-weight: bold;
}
